
table, th, td { 
    border-collapse: collapse;
}

tr.row {

    &.header {
        font-weight: bold;
        font-size: 0.8em;
        letter-spacing: 0.2em;

        td.col {
            padding-top: 1em;
            padding-top: 0.5em;

            padding-left: 1.2em;
            color: #888;
        }

        td.first {
            padding-left: 0.5em;
        }
    }

    td.col {
        vertical-align: middle;
    }
}
