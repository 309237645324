// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

h1 { 
    font-size: 1.5rem;
}

.title, .title input {
    font-weight: bold;
    font-size: 1.4rem;
    width: 100%;
    word-break: break-all;
    
    outline: none;
    border-bottom: 1px transparent;
    &:focus { 
        outline: none;
        border-bottom: 1px solid #0299D3;        
    }
}

.small-title, .small-title input {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    border: none;
    &:focus { border: none };
}

textarea {
    border:0;
    width: 100%;
}
