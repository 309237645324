$swGrey: #d4d4d4;
$swLightGrey: #F1F1F1;
$swLighterGrey: #FcFcFc;
$swDarkishGrey: #6b6b6b;
$swDarkGrey: #3A3A3A;
$swRed: #B50000;
$swGreen: #008000;

$navBarGrey: #0d2a37;

$modalGrey: #595c5f47;


@mixin colors-to-vars {    
    --swBlue: #{$swBlue};
    --swBlue3: #{$swBlue3};
}
