
.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(27, 48, 73, 0.12),
                0 8px 10px 1px rgba(27, 48, 73, 0.12),
                0 3px 14px 2px rgba(27, 48, 73, 0.12);
  }
  
  .cdk-drag-placeholder {
    opacity: 0.2;
    background-color: #D3D3D3;
  }
  
  .cdk-drag-animating {
    transition: transform 150ms cubic-bezier(0.36, 0.05, 0.2, 1);
  }
  
  .project:last-child {
    border: none;
  }
  
  projects-group.cdk-drop-list-dragging 
    .project:not(.cdk-drag-placeholder) {
    transition: transform 150ms cubic-bezier(0.36, 0.05, 0.2, 1);
  }