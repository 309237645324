@import "./colors";

.icon {
    width: 1.3rem;
    height: 1.3rem;
    background-position: center;
    background-repeat: no-repeat;

    font-size: 1.4rem;
    line-height: 1;
    mat-icon { 
        user-select: none;
    }

    &.sync {
        background-image: url("/assets/img/sketchup_icon.svg");

        &.dark {
            color: #3A3A3A;
            background-image: url("/assets/img/sketchup_icon_dark.svg");
        }
    }
    &.mr {
        margin-right: 1rem;
    }

    &.ml {
        margin-left: 1rem;
    }

}

.btn {
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;

    width: 3rem;
    height: 3rem;

    &.add {
        background-size: 2rem;
    }

    &.delete {
        background-size: 1.5rem;
    }
}


/* primary button */

.btn-primary {
    border-radius: 0.5rem;
    padding: 0 1.2rem;
    height: 2.8rem;
    margin: 0.5rem;

    display: inline-flex;
    align-items: center;

    cursor: pointer;
    user-select: none;

    font-weight: bold;
    font-size: 0.85rem;
    line-height: 1;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    .icon {
        background-position: left;
        background-repeat: no-repeat;
        background-size: 1.1rem;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.5rem;
    }

    /* colors */

    background-color: $swLightGrey;
    color: var(--swBlue3);

    &:hover, &.active {
        background-color: var(--swBlue);
        color: white;
    }

    &.destructive {
        background-color: $swRed;
        color: white;
    }
    
    &.grey {

        background-color: $swLightGrey;
        color: $swDarkGrey;

        &:hover {
            background-color: var(--swBlue);
            color: white;

            &.destructive {
                background-color: $swRed;
            }
        }
    }

    &[disabled] {
        background-color: transparent;
        border: 1px solid $swGrey;
        color: $swGrey;

        &:hover {
            cursor: not-allowed;
        }
    }

}