input, select {
    background: none;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0.5rem 0;
}

input {
    border: none;
    border-bottom: 1px solid #D3D3D3;
}

select {
    direction: rtl;
    border: 1px solid #D3D3D3;
    &:focus {
        outline-width: none;
        border: 1px solid #0299D3;
    }
}

input {
    outline: none;
    padding-right: 0.2rem;
    &:focus {
        outline: none;
        border-bottom: 1px solid #0299D3;        
    }
}

.table { display: table; width: 100%;}
.trow { display: flex; flex-direction: row; }
.tcell { display: inline-flex; }