@import "./colors";

.menu {
    position: relative;
    display: inline-flex;
    
    * {
        display: inline-flex;
    }

    .middle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3rem;
        display: inline-flex;
    }
    
    .hover-group {
        border-radius: 0.5rem;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        z-index: 10;
    }
    
}

.hover-btn {
    &.red {
        color: white;
        background-color: $swRed;
    }

    &.grey {
        color: #C4C4C4;
    }

    &.white {
        color: white;
    }

    &.transparent {
        background-color: rgba(1,1,1,0.001);
    }
}

.hover-btn {
    background-position: center;
    background-repeat: no-repeat;
    background-color: $swBlue3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    width: 3rem;
    height: 3rem;

    // background-size: 1.2rem;
    &.more {
        color: #C4C4C4;
    }

    color: white;

    // &.edit {
    //     background-image: url("/assets/img/edit_icon.svg");
    // }

    // &.duplicate {
    //     background-image: url("/assets/img/duplicate_icon.svg");
    // }

    // &.delete {
    //     background-image: url("/assets/img/delete_icon.svg");
    // }

    // &.share {
    //     background-image: url("/assets/img/share_icon.svg");
    // }

    // &.settings {
    //     background-image: url("/assets/img/settings_icon.svg");
    // }

    &.arrow {
        width: 2rem;
        height: 2rem;
        // background-image: url("/assets/img/expanded_icon.svg");

        // &.collapsed {
        //     transform: rotate(-90deg);
        // }
    }
   
    // &.save-template {
    //     background-image: url("/assets/img/save_template_icon.svg");
    // }
    
    // &.plus {
    //     background-image: url("/assets/img/plus_icon.svg");
    // }
}

