@import "./colors";

.sw-label {
    align-items: center;
    border-radius: 1em;
    padding: 0.2em 0.7em;
    margin: 0 0.2em;

    background-color: $swLightGrey;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1;

    .name {
        display: inline-block;
    }

    &.removable {
        display: flex;
    }

    .sw-label-remove {
        margin-right: -0.2em;
        margin-left: 0.4em;
        cursor: pointer;
    }
}

